import { useMutation, useQuery } from '@tanstack/react-query';
import { ShowAlert } from 'src/providers/toast';
import { group } from 'src/api';

export function useTreatGroup() {
  return useMutation(
    (groupId: string) => {
      return group.treatGroup(groupId);
    },
    {
      onSuccess: (response, variables, context) => {
        ShowAlert({ type: 'success', message: 'Success' });
      },
    },
  );
}

export function useGetFlaggedGroups() {
  return useQuery(['flagged-groups'], group.getFlaggedGroups);
}

export function useGetTreatedGroups() {
  return useQuery(['treated-groups'], group.getTreatedGroups);
}

export function useGetGroupMessages(groupId: string) {
  return useQuery(
    ['employees', { groupId }],
    () => {
      return group.getGroupMessages(groupId);
    },
    {
      select: (data) => {
        data.groupChats.docs.reverse();

        return data;
      },
    },
  );
}
