import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { tags } from 'src/api/tags';
import { useGetAppUserActivities } from 'src/redux/app-user/hooks';
import { useGetTopicAnalytics } from 'src/redux/dashboard/hooks';
import { IInterest } from 'src/redux/topic/types';
import BarChart from './bar-chart';
import DonutChart from './donut-chart';
import PieChart from './pie-chart';
import TopicCard from '../cards/topic';

function TopicTab() {
    const [contentMediaData, setContentMediaData] = useState<any>(null);
    const [generalTopicsData, setGeneralTopicsData] = useState<any>(null);
    const [topicsWithMostComments, setTopicsMostComments] = useState<any>([]);
    const [topicsWithMostLikes, setTopicsMostLikes] = useState<any>([]);
    const [topicTagData, setTopicTagData] = useState<any>(null);


    const { data: topicAnalytics, isLoading } = useGetTopicAnalytics();
    const [allInterests, setAllInterests] = useState<IInterest[]>([]);

    useEffect(() => {
        tags.getTags().then((response: any) => {
            let interestData = JSON.parse(JSON.stringify(response.data));
            setAllInterests(interestData);
        });
    }, []);

    const findInterestNameById = (id: string) => {

        const name = allInterests.find((item) => {
            return item._id == id
        });

        if (!name) {
            return id;
        }

        return name?.name;
    }



    function updateContentMediaData() {
        let data = {
            data: {
                labels: ['Text Alone', 'Video', 'Audio', 'Image', 'Document'],
                datasets: [
                    {

                        label: 'Number of Topics',
                        data: [
                            topicAnalytics.topics.data.contentStat.topicsWithOnlyText,
                            topicAnalytics.topics.data.contentStat.topicsWithVideo,
                            topicAnalytics.topics.data.contentStat.topicsWithAudio,
                            topicAnalytics.topics.data.contentStat.topicsWithImages,
                            topicAnalytics.topics.data.contentStat.topicsWithDocuments
                        ],
                        backgroundColor: [
                            'rgba(202, 73, 140, 1)',
                            'rgba(253, 227, 223, 1)',
                            'rgba(230, 191, 206, 1)',
                            'rgba(207, 155, 189, 1)',
                            'rgba(185, 119, 172, 1)',
                        ],
                    },
                ],
            },
            legends: [
                {
                    title: 'Text Only',
                    color: 'bg-[#CA498C]',
                },
                {
                    title: 'Video',
                    color: 'bg-[#FDE3DF]',
                },
                {
                    title: 'Audio',
                    color: 'bg-[#E6BFCE]',
                },
                {
                    title: 'Image',
                    color: 'bg-[#CF9BBD]',
                },
                {
                    title: 'Document',
                    color: 'bg-[#B977AC]',
                },
            ]
        };

        setContentMediaData(data);
    }

    function updateGeneralUserData() {
        let data = {
            data: {
                labels: ['Normal', 'Flagged'],
                datasets: [
                    {

                        label: '# of Topics',
                        data: [
                            topicAnalytics.topics.data.appropriateTopics,
                            topicAnalytics.topics.data.totalTopics - topicAnalytics.topics.data.appropriateTopics
                        ],
                        backgroundColor: [
                            'rgba(103, 197, 135, 1)',
                            'rgba(234, 246, 237, 1)',
                        ],
                    },
                ],
            },
            legends: [
                {
                    title: 'Normal',
                    color: 'bg-[#67C587]',
                },
                {
                    title: 'Flagged',
                    color: 'bg-[#EAF6ED]',
                },

            ]
        };

        setGeneralTopicsData(data);
    }

    function formatTopicsWithMostComments() {
        let newFormat: any = [];

        topicAnalytics.topics.data.topicsWithMostComments.forEach((item: any, index: number) => {

            newFormat.push({
                contentId:
                    item.topicData[0],
                id: item.topicData[0]._id,
                count: item.count
            })

        })


        setTopicsMostComments(newFormat);
    }

    function formatTopicsWithMostLikes() {

        let newFormat: any = [];

        topicAnalytics.topics.data.topicsWithMostLikes.forEach((item: any, index: number) => {

            newFormat.push({
                contentId:
                    item.topicData[0],
                id: item.topicData[0]._id,
                count: item.count
            })

        })


        setTopicsMostLikes(newFormat);
    }

    function updateTopicsTagData() {

        let tagsData = topicAnalytics.topics.data.topicTagsCount[0];

        let tagArray: any = [];


        Object.keys(tagsData).forEach(function (key) {
            tagArray.push({
                name: findInterestNameById(key),
                value: tagsData[key]
            })
        });


        let arr = tagArray.sort(function (a: any, b: any) { return a.value - b.value; });
        arr = arr.slice(Math.max(arr.length - 10, 0)); //get the top 10 tags
        arr = arr.reverse();

        let labels: any = [];
        let dataArray: any = [];

        arr.forEach((item: any) => {
            labels.push(item.name);
            dataArray.push(item.value);
        })


        let data = {
            data: {
                labels,
                datasets: [
                    {
                        label: '# Tags',
                        data: dataArray,
                        backgroundColor: [
                            'rgba(213, 205, 252, 1)',
                            'rgba(161, 143, 252, 1)',
                        ],
                    },
                ],
            },
        };

        console.log(data, '==== red de')

        setTopicTagData(data);
    }


    useEffect(() => {
        console.log(topicAnalytics, '= topicAnaltyics');

        if (topicAnalytics && allInterests) {
            updateContentMediaData();
            updateGeneralUserData();
            formatTopicsWithMostComments();
            formatTopicsWithMostLikes();
            updateTopicsTagData();
        }
    }, [topicAnalytics, allInterests])


    return (
        <div className='flex mt-3 flex-col'>

            {topicAnalytics ? (
                <>
                    <div className='flex flex-row items-center my-3 justify-between mr-4'>
                        <span className="header">Topics</span>
                        <span className='title-2 text-color-gray-6' > Last updated: {moment(topicAnalytics.topics.createdAt).fromNow()}</span>
                    </div>


                    {/* Section 1 */}
                    <div className='flex flex-row'>
                        <div className='box-shadow h-full flex w-full mr-4 flex-col p-4'>
                            <div className='flex flex-row'>

                                <div className='flex flex-col mb-3 '>
                                    <span className='p1 text-color-gray-6'> Total Number of Topics</span>
                                    <span className='header-2'> {topicAnalytics.topics.data.totalTopics}</span>
                                </div>
                            </div>
                            <div className='w-[150px]'>
                                <PieChart data={generalTopicsData} />
                            </div>

                        </div>

                        <div className='box-shadow h-full flex w-full mr-4 flex-col p-4'>
                            <div className='flex flex-row'>

                                <div className='flex flex-col mb-3'>
                                    <span className='p1 text-color-gray-6'> Topics by Content/media </span>
                                </div>
                            </div>

                            <div className='w-[150px]'>
                                <PieChart data={contentMediaData} />
                            </div>

                        </div>

                        <div className='w-full'>

                        </div>

                    </div>

                    {/* Section 2 */}
                    <div className='flex flex-row mt-3'>

                        <div className='box-shadow h-full flex w-1/2 mr-4 flex-col p-4'>

                            <div className='flex flex-col mb-3 text-center'>
                                <span className='p1'>Ten Most used tags </span>
                            </div>


                            <div className='w-full'>
                                <BarChart data={topicTagData} />
                            </div>


                        </div>

                    </div>

                    {/* Section 3 */}
                    <div className='flex flex-row mt-3'>

                        <div className='box-shadow h-full flex w-full mr-4 flex-col p-4'>

                            <div className='flex flex-col mb-3'>
                                <span className='p1 text-color-gray-6'> Five Topics with most likes </span>
                            </div>

                            {topicsWithMostLikes.map((item: any, index: number) => (
                                <div className='flex flex-col my-2'>
                                    <TopicCard topic={item} showCommentsAndLikes={false} />
                                    <div className='mt-3 bg-[#F2F2F2] w-full h-[35px] flex justify-center items-center'>
                                        <span className='p1-semi-bold text-[#4F4F4F]'> {item.count} {item.count > 1 ? 'Comments' : "Comment"} </span>
                                    </div>

                                </div>



                            ))}




                        </div>

                        <div className='box-shadow h-full flex w-full mr-4 flex-col p-4'>

                            <div className='flex flex-col mb-3'>
                                <span className='p1 text-color-gray-6'> Five Topics with most comments </span>
                            </div>

                            {topicsWithMostComments.map((item: any, index: number) => (
                                <div className='flex flex-col my-2'>
                                    <TopicCard topic={item} showCommentsAndLikes={false} />
                                    <div className='mt-3 bg-[#F2F2F2] w-full h-[35px] flex justify-center items-center'>
                                        <span className='p1-semi-bold text-[#4F4F4F]'> {item.count} {item.count > 1 ? 'Comments' : "Comment"} </span>
                                    </div>

                                </div>
                            ))}

                        </div>

                        <div className='w-full'>

                        </div>



                    </div>

                </>

            ) : null}




        </div>
    )
}

export default TopicTab;